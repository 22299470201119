import * as React from 'react';
import cn from 'clsx';
import { getDataAttributes } from '@wix/editor-elements-common-utils';
import { MenuItemProps } from '@wix/editor-elements-definitions';
import { FC } from 'react';
import { SubmenuProps } from '../types';
import { useMenuItemContext } from '../../StylableHorizontalMenu/viewer/MenuItemContext';
import styles from './Submenu.scss';

type ItemListProps = {
  items: Array<MenuItemProps>;
  rootList: boolean;
};

const ItemList: FC<ItemListProps> = ({ items, rootList }) => {
  return (
    <ul
      className={cn(rootList ? styles.rootList : styles.subList, styles.list)}
      style={
        {
          '--itemsNumber': items.length,
        } as React.CSSProperties
      }
    >
      {items.map((subItem, i) => {
        return (
          <li
            key={i}
            className={cn(
              subItem.items?.length && styles.itemWithSubList,
              styles.item,
            )}
          >
            <div className={styles.itemLabel}>{subItem.label}</div>
            {subItem.items?.length && (
              <ItemList items={subItem.items} rootList={false} />
            )}
          </li>
        );
      })}
    </ul>
  );
};

const Submenu: React.FC<SubmenuProps> = props => {
  const { id } = props;

  const item = useMenuItemContext();

  const { items } = item;

  return (
    <div
      id={id}
      {...getDataAttributes(props)}
      className={cn(styles.root)}
      data-hovered
      data-shown
    >
      {items?.length ? (
        <ItemList rootList={true} items={items} />
      ) : (
        <div className={styles.empty}>NO MENU ITEMS WERE FOUND</div>
      )}
    </div>
  );
};

export default Submenu;
